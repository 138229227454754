import { Container, Grid } from '@chakra-ui/react';
import { Newsletter as Props } from '@/types';
import Image from '../Image';
import Teaser from '../Teaser';

const Newsletter = ({ title, lead, link }: Props) => (
  <Grid alignItems="center" as="section" position="relative">
    <Image src="/newsletter.jpg" alt={title} layout="fill" />
    <Teaser
      as={Container}
      gridRow="1"
      gridColumn="1"
      zIndex="1"
      heading={{ title, as: 'h2', variant: 'h3' }}
      textStyle="md"
      lead={lead}
      link={link}
      py={['12.5%', '10%', '7.125%']}
      alignInnerItems="center"
      innerProps={{
        color: 'white',
        textAlign: 'center',
      }}
    />
  </Grid>
);

export default Newsletter;
